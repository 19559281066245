import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';


import axios from 'axios'
import Invoice from './Invoice';
import List from './List';
import Test from './test';
import TestInvoice from './Testinvoice';
import Login from './Login';
import Protected from './Protected';
import Signup from './Signup';


// function App() {


// const [amount,setAmount]= useState('')
// const [from,setfrom]= useState('')
// const [to,setto]= useState('')
// const [Description,setDescription]= useState('')
// const [status,setstatus]= useState('')

// const handlesubmit= ()=>{

//   console.log(from,to,amount,Description)
//   setstatus("invoice-created")
 

//   const postData = {
//     from_field: from,
//     to_field: to,
//     amount: amount,
//     description: Description,
//     status: status
//   };
  
//   try {
//     axios.post('http://localhost:4000/post-invoice', postData)
//       .then(response => {
//         console.log("Posted", response.data);
//       })
//       .catch(error => {
//         console.error("Error posting:", error);
//       });
//   } catch (error) {
//     console.error("An unexpected error occurred:", error);
//   }

// }





//   return (
//     <div className="App">
//       <div class="login-box">
 
//  <form onSubmit={handlesubmit}>
//     <h1 style={{color:'white'}}>
//       Invoice No:
//     </h1>

//    <div class="user-box">
//      <input type="text" name="" required="" onChange={(e)=>(setfrom(e.target.value))}/>
//      <label>From</label>
//    </div>
//    <div class="user-box">
//      <input type="" name="" required=""  onChange={(e)=>(setto(e.target.value))}/>
//      <label>TO</label>
//    </div>
   
//    <div class="user-box">
//      <input type="" name="" required="" onChange={(e)=>(setAmount(e.target.value))}/>
//      <label>Amount</label>
//    </div>
   
//    <div class="user-box">
//      <input type="" name="" required="" onChange={(e)=>(setDescription(e.target.value))}/>
//      <label>Description</label>
//    </div>
   
   
//    <p style={{color:"white"}} >TOTAL AMOUNT: {amount} </p>
//    <center>
//    <button type="submit">
//           Pay Now
//       <span></span>
//    </button></center>
//  </form>
// </div>

//     </div>
//   );
//   }





// Create your App component with routes
function App() {
  return (
    <Router> {/* Define your routes */}
       <Routes >
       {/* <Route path="/invoice" exact element={<Invoice />} /> */}

       <Route path="/invoice" exact element={<Protected Component={Invoice} />} />


       {/* <Route path="/list" exact element={<List />} /> */}

       
       <Route path="/list" exact element={<Protected Component={TestInvoice} />} />


       <Route path="/" exact element={<Signup />} />


       <Route path="/login" exact element={<Login />} />



       {/* <Route path="/testinvoice" exact element={<TestInvoice />} /> */}
        </Routes >
    </Router>
  );
}


export default App;
