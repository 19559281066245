import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


function Protected (props){

    const navigate = useNavigate()

    const {Component} = props

    useEffect(()=>{
        let login= localStorage.getItem('login');

        if(!login){
            navigate('/')
        }
    })


    return (
        <div>
            <Component />
        
        </div>
    )


}

export default Protected;