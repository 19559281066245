
import React, { useEffect, useState } from 'react';
import logo from '../src/img/logo.png';
import axios from 'axios'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";



 const TestInvoice=()=> {

    const [selectedDate, setSelectedDate] = useState(null);


    const handleChange = (date) => {
        setSelectedDate(date);
      };
    


      const formatDate = (date) => {
        if (!date) return '';
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;


        
      };



      const [invoices, setInvoices] = useState([]);
      const [loading, setLoading] = useState(true);
    



    const datePickerStyle = {
        backgroundColor: 'white'
      };
   
        const [startDate, setStartDate] = useState(new Date());



    const [amount,setAmount]= useState('')
    const [from,setfrom]= useState('')
    const [to,setto]= useState('')
    const [Description,setDescription]= useState('')
    const [status,setstatus]= useState('')
    
    const handlesubmit= (e)=>{
        
        e.preventDefault();
        
        console.log(startDate)

      console.log(from,to,amount,Description)
      setstatus("invoice-created")
     
    
      const postData = {
        from_field: from,
        to_field: to,
        amount: amount,
        description: Description,
        status: "invoice-created"
      };
      
      try {
        axios.post('http://localhost:4000/post-invoice', postData)
          .then(response => {
            console.log("Posted", response.data);
          })
          .catch(error => {
            console.error("Error posting:", error);
          });
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }
    
    }
    
    

    const [currentDate, setCurrentDate] = useState('');

    const getCurrentDate = () => {
      const now = new Date();
      const day = String(now.getDate()).padStart(2, '0');
      const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0!
      const year = now.getFullYear();
      setCurrentDate(`${day}/${month}/${year}`);

      console.log(currentDate)
    };

    const params = {
      username: 'Brand_Revam',
      password: 'Live@br23',
      startDate: '2024/01/01',
      endDate: '2030/01/01'
    };

    useEffect(() => {
      // Define a function to fetch data from the database
      const fetchData = async () => {
        try {
              console.log("TTSTSTST")

          const response = await axios.get('https://paypro-production.up.railway.app/get-invoices'); // Replace with your actual endpoint
          setInvoices(response.data); // Assuming the response data is an array of invoices
          setLoading(false);
          console.log(invoices,"invoices")
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const getPaidorders = async ()=>{
        try {
          const response = await axios.get('https://paypro-production.up.railway.app/get-paidorders',{params}); // Replace with your actual endpoint
          console.log("Paid Orders",response)
        }
        catch(error){
          console.log(error)
        }

      };

  
      // Call the fetchData function
      fetchData();
      getPaidorders();
    }, []); // The empty dependency array ensures that the effect runs only once when the component mounts
  




    // Render loading state if data is still being fetched
    if (loading) {
      return <div>Loading...</div>;
    }
  
 
  
  



    
    
    
      return (
        <div className='list'>

         
            <section class="invoice-brandrevem1">
        <div class="contanir">
            <div class="main-invoice">
                <header class="logo-invoice">
                    <img src={logo} alt=""/>


                    <div class="invoice-date-box">
                        {/* <p>Invoice # 12345</p> */}
                        {/* <p>Date : 5/2/2024</p> */}
                    </div>
                </header>

                   <form class="main-form" action="">

                   <h1>Invoices Details</h1>

                   <table id="customers">
  <thead>
    <tr>
      <th>Invoice No</th>
      <th>TO</th>
      <th>Amount</th>
      <th>Description</th>
      <th>Issue Date</th>
      <th>Due Date</th>
      <th>Payment Status</th>
    </tr>
  </thead>
  <tbody className='list-body'>
    {invoices.map((invoice) => (
      <tr key={invoice._id}>
        <td>{invoice.orderNumber}</td>
        <td>{invoice.customerName}</td>
        <td>{invoice.orderAmount}</td>
        <td>{invoice.description}</td>
        <td>{(new Date(invoice.issueDate)).toLocaleDateString()}</td>
        <td>{(new Date(invoice.orderDueDate)).toLocaleDateString()}</td>
        {/* <td>{invoice.orderDueDate}</td> */}
        <td>{invoice.transactionStatus}</td>
        {/* <td>{invoice.payment_status}</td> */}
      </tr>
    ))}
  </tbody>
</table>



                    </form>
            </div>
        </div>



    </section>

    <footer class="company-footer">
        <div class="contanir">
           <div class="main-footer1">
            <div class="footer-id">
                <h2>INFO@BRANDREVAM.COM</h2>
            </div>
            <div class="footer-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M13.5507 21.1377C13.5489 21.0872 13.5455 21.0365 13.5455 20.986C13.5451 19.2679 13.5453 17.5499 13.5453 15.8318C13.5453 15.7819 13.5453 15.7318 13.5453 15.6669C12.9954 15.6669 12.455 15.6669 11.9087 15.6669C11.9087 14.9507 11.9087 14.2493 11.9087 13.5333C12.4494 13.5333 12.9902 13.5333 13.5453 13.5333C13.5453 13.4733 13.5451 13.4278 13.5453 13.3823C13.5475 12.8241 13.5349 12.265 13.5554 11.7075C13.5802 11.0353 13.7571 10.4188 14.1796 9.91695C14.501 9.53507 14.9021 9.31888 15.3547 9.21038C15.7225 9.12208 16.0947 9.13238 16.4663 9.14855C16.7684 9.16148 17.0698 9.19078 17.3716 9.21341C17.3953 9.21522 17.4185 9.2227 17.4472 9.22876C17.4472 9.85937 17.4472 10.4863 17.4472 11.1309C17.4112 11.1309 17.3746 11.1303 17.3381 11.1309C16.9663 11.1369 16.5938 11.1309 16.2226 11.1525C15.7837 11.178 15.5356 11.4515 15.5174 11.9474C15.4985 12.4618 15.5109 12.9778 15.5098 13.4931C15.5098 13.4999 15.5145 13.507 15.5217 13.5272C16.1341 13.5272 16.7509 13.5272 17.3854 13.5272C17.3032 14.2445 17.2228 14.9462 17.1409 15.6605C16.5983 15.6605 16.061 15.6605 15.5158 15.6605C15.5122 15.7063 15.5073 15.7397 15.5073 15.7728C15.5069 17.5337 15.5069 19.2948 15.5071 21.0557C15.5071 21.0827 15.5105 21.11 15.5123 21.1371C14.8588 21.1377 14.2047 21.1377 13.5507 21.1377Z" fill="white"/>
                    <circle cx="15" cy="15" r="14" stroke="white" stroke-width="2"/>
                  </svg>

                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <circle cx="15" cy="15" r="14" stroke="white" stroke-width="2"/>
                    <path d="M7.81885 19.8326C9.38844 19.9396 10.8085 19.5557 12.0979 18.557C10.7457 18.4303 9.85337 17.7311 9.3547 16.4484C9.79525 16.4484 10.2068 16.4484 10.6182 16.4484C10.6229 16.4268 10.6281 16.4052 10.6328 16.3836C9.96773 16.2094 9.41516 15.8576 8.99194 15.3105C8.56873 14.763 8.3522 14.1377 8.34283 13.4034C8.75761 13.6021 9.16489 13.7513 9.62513 13.7571C8.37657 12.6346 7.99132 11.3307 8.75948 9.73026C10.3591 11.6441 12.3486 12.7176 14.7707 12.8779C14.7707 12.5463 14.7501 12.2373 14.774 11.932C14.8977 10.3565 16.2077 9.15294 17.7506 9.18317C18.5281 9.19853 19.1964 9.48647 19.757 10.0345C19.8329 10.1084 19.8957 10.131 20.0021 10.1041C20.5495 9.96637 21.0739 9.77009 21.5684 9.49223C21.6031 9.47255 21.6406 9.45816 21.7198 9.4212C21.4887 10.1281 21.0838 10.644 20.5237 11.0663C20.6948 11.0317 20.8673 11.003 21.0369 10.9612C21.2127 10.9185 21.3875 10.8681 21.5595 10.8124C21.7296 10.7572 21.896 10.6915 22.1046 10.6157C21.851 11.0269 21.5707 11.359 21.2563 11.6609C21.0988 11.8121 20.9347 11.9584 20.7618 12.0904C20.6653 12.1638 20.6371 12.2387 20.6409 12.3587C20.7459 15.6868 18.8454 18.9741 15.9283 20.3341C13.2138 21.5996 10.5273 21.4427 7.93227 19.9094C7.89337 19.8864 7.85681 19.858 7.81885 19.8326Z" fill="white"/>
                  </svg>



                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <circle cx="15" cy="15" r="14" stroke="white" stroke-width="2"/>
                    <path d="M15.5554 19.8192C14.7198 19.8192 13.9054 19.8192 13.0776 19.8192C13.0776 17.1555 13.0776 14.493 13.0776 11.8199C13.8596 11.8199 14.6435 11.8199 15.447 11.8199C15.447 12.1737 15.447 12.5271 15.447 12.897C15.4799 12.8782 15.4955 12.8747 15.5014 12.8653C16.0239 12.0234 16.8054 11.6505 17.7714 11.6215C18.3232 11.6051 18.8633 11.672 19.3697 11.9107C20.0253 12.2199 20.3928 12.7619 20.5955 13.4359C20.7571 13.9736 20.8131 14.5278 20.817 15.0847C20.8276 16.6279 20.8233 18.1711 20.8248 19.7143C20.8248 19.7377 20.8213 19.7612 20.819 19.7945C19.9939 19.7945 19.1756 19.7945 18.3341 19.7945C18.3341 19.7409 18.3341 19.6849 18.3341 19.6286C18.3338 18.3198 18.3377 17.0111 18.3291 15.7023C18.3271 15.4061 18.3036 15.1059 18.25 14.8147C18.0989 13.9956 17.5659 13.7173 16.8023 13.8073C16.1072 13.8895 15.7245 14.2961 15.6165 15.0483C15.5769 15.3227 15.5593 15.6021 15.5578 15.8796C15.5519 17.1296 15.555 18.3797 15.555 19.6301C15.5554 19.688 15.5554 19.746 15.5554 19.8192Z" fill="white"/>
                    <path d="M9.02441 11.8193C9.85529 11.8193 10.6733 11.8193 11.4994 11.8193C11.4994 14.4869 11.4994 17.1455 11.4994 19.8151C10.6752 19.8151 9.85373 19.8151 9.02441 19.8151C9.02441 17.151 9.02441 14.492 9.02441 11.8193Z" fill="white"/>
                    <path d="M11.698 9.25573C11.7023 10.0514 11.0569 10.7108 10.2695 10.7155C9.47772 10.7202 8.81826 10.0596 8.81787 9.2612C8.81748 8.47611 9.46246 7.82644 10.2495 7.81939C11.0432 7.81235 11.6937 8.45733 11.698 9.25573Z" fill="white"/>
                  </svg>
            </div>
           </div>
        </div>
    </footer>
        </div>
      );
      }
    
    export default TestInvoice
    