
import React, { useEffect, useState } from 'react';
import table from 'bootstrap'

import axios from 'axios'
import logo from '../src/img/logo.png';



 const List=()=> {


    const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);


    const [amount,setAmount]= useState('')
    const [from,setfrom]= useState('')
    const [to,setto]= useState('')
    const [Description,setDescription]= useState('')
    const [status,setstatus]= useState('')



    useEffect(() => {
        // Define a function to fetch data from the database
        const fetchData = async () => {
          try {
                console.log("TTSTSTST")

            const response = await axios.get('http://paypro-production.up.railway.app/get-invoices'); // Replace with your actual endpoint
            setInvoices(response.data); // Assuming the response data is an array of invoices
            setLoading(false);
            console.log(invoices,"invoices")
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        // Call the fetchData function
        fetchData();
      }, []); // The empty dependency array ensures that the effect runs only once when the component mounts
    
      // Render loading state if data is still being fetched
      if (loading) {
        return <div>Loading...</div>;
      }
    
   
    
    
    
    
      return (

        <div className='contanir'>

          <div className='main-'></div>

<div class="logo-invoice1">
                <img src={logo} alt="Logo" />

                    <div class="invoice-date-box">
                        {/* <p>InvoiceNo: BR {recordCount}</p>
                        <p>Date : {currentDate}</p> */}
                    </div>
                </div>

               <div className='main-hello'>
      <h1>Invoices</h1>
      </div>
   

{console.log(invoices)}


<table id="customers">
  <thead>
    <tr>
      <th>Invoice No</th>
      <th>TO</th>
      <th>Amount</th>
      <th>Description</th>
      <th>Payment Status</th>
    </tr>
  </thead>
  <tbody>
    {invoices.map((invoice) => (
      <tr key={invoice._id}>
        <td>{invoice.orderNumber}</td>
        <td>{invoice.customerName}</td>
        <td>{invoice.orderAmount}</td>
        <td>{invoice.description}</td>
        <td>{invoice.transactionStatus}</td>
        {/* <td>{invoice.payment_status}</td> */}
      </tr>
    ))}
  </tbody>
</table>



    
        </div>

     
      );
      }
    
    export default List
    